import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MainSakuYouth } from 'application/hooks/useSakuAnakUser';

export interface ISakuAnakState {
  sakuYouth: MainSakuYouth | null;
  tempDataSaku: TempDataSaku | null;
}

export interface TempDataSaku {
  date: string;
  time: string;
  dorc: string;
  nominal: number;
  trrefn: string;
  remark: string;
  text?: string;
  icon?: string
}

const initialState = {
  sakuYouth: null,
  tempDataSaku: null,
} as ISakuAnakState;

const sakuAnakSlice = createSlice({
  name: 'sakuAnak',
  initialState,
  reducers: {
    setSakuYouth: (state, action: PayloadAction<MainSakuYouth>) => {
      state.sakuYouth = {
        ...state.sakuYouth,
        ...action.payload,
      };
    },
    setTempDataSaku: (state, action: PayloadAction<TempDataSaku>) => {
      state.tempDataSaku = {
        ...state.tempDataSaku,
        ...action.payload,
      };
    },
    removeTempDataSaku: (state) => {
      state.tempDataSaku = null;
    },
  },
});

export const { setSakuYouth, setTempDataSaku, removeTempDataSaku } = sakuAnakSlice.actions;
export default sakuAnakSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveFilter } from 'application/models/active_filter';

interface QrisTransactionState {
  historyFilter: ActiveFilter | null;
}

const qrisTransactionSlice = createSlice({
  name: 'qrisTransaction',
  initialState: {
    historyFilter: null,
  } as QrisTransactionState,
  reducers: {
    setHistoryFilter: (state, action: PayloadAction<ActiveFilter>) => {
      state.historyFilter = {
        ...state.historyFilter,
        ...action.payload,
      };
    },
    removeHistoryFilter: (state) => {
      state.historyFilter = null;
    },
  },
});

export const { setHistoryFilter, removeHistoryFilter } = qrisTransactionSlice.actions;
export default qrisTransactionSlice.reducer;

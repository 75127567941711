import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveFilter } from 'application/models/active_filter';

interface QrisTransactionFilterState {
  historyFilter: ActiveFilter | null;
}

const qrisTransactionHistoryFilterSlice = createSlice({
  name: 'qrisTransactionHistoryFilter',
  initialState: {
    historyFilter: {
      tabFilter: 'all',
      activeFilter: '',
    },
  } as QrisTransactionFilterState,
  reducers: {
    setQrisHistoryFilter: (state, action: PayloadAction<ActiveFilter>) => {
      state.historyFilter = {
        ...state.historyFilter,
        ...action.payload,
      };
    },
    removeQrisHistoryFilter: (state) => {
      state.historyFilter = null;
    },
  },
});

export const { setQrisHistoryFilter, removeQrisHistoryFilter } = qrisTransactionHistoryFilterSlice.actions;
export default qrisTransactionHistoryFilterSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UseBankListData } from '../../hooks/useBankList';
import { TransactionModel } from '../../models/transaction_model';

export interface SavedCheckDestinationResult {
  name?: string;
  number?: string;
  code?: string;
  transferMethod?: string;
  trxType?: string;
  isFavorite?: boolean;
  biFastStatus?: string;
}

export interface ITransferState {
  bankDestination: UseBankListData | null;
  data: TransactionModel | null;
  savedCheckDestinationResult: SavedCheckDestinationResult;
}

const transferSlice = createSlice({
  name: 'transfer',
  initialState: {
    bankDestination: {},
    data: {},
    savedCheckDestinationResult: {},
  } as ITransferState,
  reducers: {
    addTransfer: (state, action: PayloadAction<TransactionModel>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    addBankDestination: (state, action: PayloadAction<UseBankListData>) => {
      state.bankDestination = action.payload;
    },
    clearTransfer: (state) => {
      state.data = {};
      state.bankDestination = {};
      state.savedCheckDestinationResult = {};
    },
    addSavedCheckDestinationResult: (state, action: PayloadAction<SavedCheckDestinationResult>) => {
      state.savedCheckDestinationResult = {
        ...state.savedCheckDestinationResult,
        ...action.payload,
      };
    },
    clearSavedCheckDestinationResult: (state) => {
      state.savedCheckDestinationResult = {};
    },
  },
});

export const {
  addTransfer,
  addBankDestination,
  clearTransfer,
  addSavedCheckDestinationResult,
  clearSavedCheckDestinationResult,
} = transferSlice.actions;
export default transferSlice.reducer;
